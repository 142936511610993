@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  height: 100%;
}

a,
button {
  @apply border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-secondary-300;
}

a {
  @apply font-medium text-secondary-600 hover:text-secondary-400 dark:text-secondary-150 dark:hover:text-secondary-50;
}

h1,
h2,
h3,
h4 {
  @apply font-heading;
}
